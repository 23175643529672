// i18next-extract-mark-ns-start paypal-for-shopify
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import shopify_paypal from 'images/shopify_paypal.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="paypal-for-shopify"
        title={t('PayPal Shopify | MONEI')}
        description={t(
          'Add the Shopify PayPal integration to your store to let customers pay with the most popular digital wallet in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Shopify PayPal integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                PayPal is the most popular digital wallet worldwide, that’s why it’s important to
                accept this payment method in your Shopify store. To do this, you’ll need a payment
                provider that supports a Shopify PayPal integration.
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to accept and manage all the payment
                methods you need in a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={shopify_paypal} alt="MONEI paypal" title="MONEI paypal" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept the widest range of payment methods in your Shopify store</Trans>
              </SectionHeader>
              <Trans parent="p">
                PayPal is widely used, but it shouldn’t be the only method of payment you accept in
                your Shopify store. Modern consumers want an abundance of payment options to choose
                from. This includes{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , more <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                . Plus <BlogLink slug="local-payment-methods">local payment methods</BlogLink> such
                as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                We want to help you accept more payment methods so you can reach more people,
                increase customer satisfaction, and sell more. That’s why we aggregate all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Increase sales by approving more online payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Relying on <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink>{' '}
                alone can lead to{' '}
                <BlogLink slug="online-payment-failure-message">payment failure</BlogLink> messages
                and lost sales. Use our{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  payments orchestration
                </InternalPageLink>{' '}
                feature to create payment processing rules. This way, if your default provider is
                experiencing downtime, we’ll send the transaction to another payment processor. Your
                customers will enjoy a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience
                and you’ll approve more payments and increase sales.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the best Shopify payment gateway pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                Enjoy selling more and paying less on transaction fees with a dynamic pricing model
                that grows with your business. As you sell more, your fees will decrease in
                real-time.
              </Trans>
              <Trans parent="p">
                Choose a flexible{' '}
                <InternalPageLink slug="shopify-payment-gateway">
                  Shopify payment gateway
                </InternalPageLink>{' '}
                that scales with your business —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add the Shopify PayPal integration to your website</Trans>
            </SectionHeader>
            <Trans parent="p">
              Set up your Shopify PayPal integration by following these steps:
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360017812937-How-do-I-add-PayPal-to-my-account-">
                  Configure PayPal payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/">
                  Connect MONEI
                </AnchorLink>{' '}
                to your Shopify store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "paypal-for-shopify"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
